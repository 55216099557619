<template>
  <div class="event-content">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          @click="getSpecialEvents(upcomingSpecialEventPage)"
          id="pills-scheduled-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-scheduled"
          type="button"
          role="tab"
          aria-controls="pills-scheduled"
          aria-selected="true"
        >
          Scheduled
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          @click="getSpecialEvents(completedSpecialEventPage)"
          id="pills-history-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-history"
          type="button"
          role="tab"
          aria-controls="pills-history"
          aria-selected="false"
        >
          History
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-scheduled"
        role="tabpanel"
        aria-labelledby="pills-scheduled-tab"
      >
        <div class="row">
          <webinar-loader v-if="specialEventLoader == true"></webinar-loader>
          <WebinarCard
            v-else
            v-for="(upcomingSpecialEvent,
            upcomingSpecialEventIndex) in upcomingSpecialEvents"
            :key="upcomingSpecialEventIndex"
            :webinar="upcomingSpecialEvent"
          />
          <div
            class="empty"
            v-if="
              upcomingSpecialEvents.length == 0 && specialEventLoader == false
            "
          >
            Please purchase special events
          </div>
        </div>
        <div class="paging-wrapper">
          <v-pagination
            v-model="upcomingSpecialEventPage"
            :pages="upcomingSpecialEventTotalPages"
            :range-size="1"
            active-color="#DCEDFF"
            @update:modelValue="upcomingSpecialEventChangeHandler"
            v-if="upcomingSpecialEventTotalClass > 0"
          />
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="pills-history"
        role="tabpanel"
        aria-labelledby="pills-history-tab"
      >
        <div class="row">
          <webinar-loader v-if="specialEventLoader == true"></webinar-loader>
          <WebinarCard
            v-else
            v-for="(completedSpecialEvent,
            completedSpecialEventIndex) in completedSpecialEvents"
            :key="completedSpecialEventIndex"
            :webinar="completedSpecialEvent"
          />
          <div
            class="empty"
            v-if="
              completedSpecialEvents.length == 0 && specialEventLoader == false
            "
          >
            You have no completed special events
          </div>
        </div>
        <div class="paging-wrapper">
          <v-pagination
            v-model="completedSpecialEventPage"
            :pages="completedSpecialEventTotalPages"
            :range-size="1"
            active-color="#DCEDFF"
            @update:modelValue="completedSpecialEventChangeHandler"
            v-if="completedSpecialEventTotalClass > 0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebinarCard from "@/components/WebinarCard.vue";
import SpecialEventService from "@/services/SpecialEventService.js";
import errorLog from "@/errorLog";
import "vue3-pagination/dist/vue3-pagination.css";
import WebinarLoader from "@/components/loaders/WebinarLoader";
import VPagination from "vue3-pagination";
export default {
  name: "ClassTab",
  components: {
    WebinarCard,
    VPagination,
    WebinarLoader
  },
  computed: {
    nestMember() {
      console.log(this.$store.getters["privilege/isPupaMember"]);
      return this.$store.getters["privilege/isPupaMember"];
    }
  },
  watch: {
    nestMember: function() {
      if (this.$store.getters["privilege/isPupaMember"] == false) {
        this.$toast.error("No permission to access this page ");
        this.$router.push({ name: "dashboardTab" });
      }
    }
  },
  data() {
    return {
      upcomingSpecialEvents: [],
      specialEventLoader: true,
      completedSpecialEventPage: 1,
      upcomingSpecialEventPage: 1,
      completedSpecialEvents: [],
      upcomingSpecialEventTotalPages: 0,
      upcomingSpecialEventTotalClass: 0,
      completedSpecialEventTotalPages: 0,
      completedSpecialEventTotalClass: 0
    };
  },
  created() {
    this.getSpecialEvents(0);
  },
  methods: {
    async getSpecialEvents(page) {
      this.specialEventLoader = true;
      await SpecialEventService.getSpecialEvents(page)
        .then(result => {
          this.specialEventLoader = false;
          this.upcomingSpecialEvents = result.data.upcoming_special_events.data;
          this.upcomingSpecialEventTotalPages =
            result.data.upcoming_special_events.last_page;
          this.upcomingSpecialEventTotalClass =
            result.data.upcoming_special_events.total;

          this.completedSpecialEvents =
            result.data.completed_special_events.data;
          this.completedSpecialEventTotalPages =
            result.data.completed_special_events.last_page;
          this.completedSpecialEventTotalClass =
            result.data.completed_special_events.total;
          this.scrollTop();
        })
        .catch(error => {
          this.specialEventLoader = false;
          errorLog.log(error);
        });
    },
    upcomingSpecialEventChangeHandler(selectedPage) {
      this.upcomingSpecialEventPage = selectedPage;
      this.getSpecialEvents(selectedPage);
    },

    completedSpecialEventChangeHandler(selectedPage) {
      this.completedSpecialEventPage = selectedPage;
      this.getSpecialEvents(selectedPage);
    },
    scrollTop: function() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
      }, 20);
    }
  }
};
</script>

<style lang="scss">
@import "@/style/events-tab.scss";
</style>
